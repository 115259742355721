<template>
  <div v-if="!state.isRenameLoading">
    <PWPopup
      ref="popupRef"
      title="Редактирование виджета"
      @after-enter="inputNameRef && inputNameRef.focus()"
    >
      <form @submit.prevent="handleSubmit">
        <PWInput
          ref="inputNameRef"
          v-model="state.form.name"
          placeholder="Название виджета"
          :is-error="v$.form.name.$error || state.uniqueNameError"
          @input="state.uniqueNameError = false"
        />
        <span
          v-if="v$.$error && !v$.form.name.required"
          :class="$style.error"
        >
          Данное поле не может быть пустым
        </span>
        <span
          v-else-if="state.uniqueNameError"
          :class="$style.error"
        >
          Данное название уже используется
        </span>
      </form>

      <template #footer>
        <PWButton
          variant="outline"
          :disabled="state.isRenameLoading"
          @click="cancel"
        >
          Отменить
        </PWButton>
        <PWButton :disabled="state.isRenameLoading" @click="handleSubmit">
          Сохранить
        </PWButton>
      </template>
    </PWPopup>
  </div>
  <div v-else>
    <PWFullScreenPreloader />
  </div>
</template>

<script setup lang="ts">
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useNotification } from '@kyvg/vue3-notification'
import { useSettingsStore } from '~/store/settings'
import PWPopup from '~/components/_ui/PWPopUp.vue'
import PWInput from '~/components/_ui/PWInput.vue'
import PWButton from '~/components/_ui/Buttons/PWButton.vue'
import PWFullScreenPreloader from '~/components/_ui/PWFullScreenPreloader.vue'
import type { Settings } from '~/types/settings'
import { removeDraftAction } from '@/utils/draftTools'

interface PopupRenameWidgetState {
  form: any,
  editSetting: Settings | null,
  isRenameLoading: Boolean,
  uniqueNameError: Boolean
}

const settingsStore = useSettingsStore()
const { notify } = useNotification()

// data
const state = reactive<PopupRenameWidgetState>({
  form: {
    name: 'Виджет 1'
  },
  editSetting: null,
  isRenameLoading: false,
  uniqueNameError: false
})

// refs
const popupRef = ref<typeof PWPopup>()
const inputNameRef = ref<typeof PWInput>()

// emits
const emit = defineEmits(['cancel'])

// vuelidate
const v$ = useVuelidate({
  form: {
    name: {
      required
    }
  }
}, state)

// computed
const settings = computed(() => settingsStore.settings)

// methods
const open = (settings: Settings) => {
  state.editSetting = settings
  state.form.name = settings.name
  popupRef.value?.toggle(true)
}

const cancel = () => {
  popupRef.value?.toggle(false)
  emit('cancel')
}

const handleSubmit = async () => {
  v$.value.$touch()
  if (v$.value.$error || state.editSetting === null) {
    return
  }

  const isNameUnique = checkForUniqueSettingName(state.form.name)
  if (!isNameUnique) {
    state.uniqueNameError = true
    return
  }

  state.isRenameLoading = true
  const renameEither = await settingsStore.renameSettings(
    state.form.name,
    state.editSetting?.id
  )

  removeDraftAction()
  renameEither.mapRight(() => {
    state.isRenameLoading = false
    notify({
      title: 'Виджет успешно сохранен',
      type: 'success',
      duration: 2500
    })
  })
}

const checkForUniqueSettingName = (name: string) => {
  return !settings.value?.some((setting: Settings) => setting.name === name)
}

// expose
defineExpose({
  open
})
</script>

<style module lang="scss">
.error {
  display: block;
  font-size: 12px;
  color: var(--main-red);
}
</style>
