<template>
  <div v-if="!state.isDeleteLoading">
    <PWPopup
      ref="popupRef"
      title="Удалить виджет?"
    >
      <form @submit.prevent="handleSubmit">
        Виджет удалится со всеми привязанными призывами к действию.
      </form>
      <template #footer>
        <PWButton
          variant="outline"
          @click="cancel"
        >
          Отменить
        </PWButton>
        <PWButton :disabled="state.isDeleteLoading" @click="handleSubmit">
          Удалить
        </PWButton>
      </template>
    </PWPopup>
  </div>
  <div v-else>
    <PWFullScreenPreloader />
  </div>
</template>

<script setup lang="ts">
import { useNotification } from '@kyvg/vue3-notification'
import PWPopup from '~/components/_ui/PWPopUp.vue'
import PWButton from '~/components/_ui/Buttons/PWButton.vue'
import PWFullScreenPreloader from '~/components/_ui/PWFullScreenPreloader.vue'
import { useAuthStore } from '~/store/auth'
import { useApi } from '~/composables/useApi'
import { useSettingsStore } from '~/store/settings'
import type { Settings } from '~/types/settings'
import { removeDraftAction } from '@/utils/draftTools'

interface PopupDeleteWidgetState {
  isDeleteLoading: Boolean,
  settings: Settings | null
}

const authStore = useAuthStore()
const settingsStore = useSettingsStore()
const api = useApi()
const { notify } = useNotification()

// data
const state = reactive<PopupDeleteWidgetState>({
  isDeleteLoading: false,
  settings: null
})

// refs
const popupRef = ref<typeof PWPopup>()

// emits
const emit = defineEmits(['cancel'])

// computed
const userId = computed(() => authStore.user?.id)
const widgetCount = computed(() => settingsStore.widgetCount)

// methods
const open = (s: any) => {
  state.settings = s
  popupRef.value?.toggle(true)
}

const cancel = () => {
  popupRef.value?.toggle(false)
  emit('cancel')
}

const handleSubmit = async () => {
  if (state.settings === null) { return }

  state.isDeleteLoading = true

  const deleteEither = await settingsStore.deleteSettings(state.settings.id)

  removeDraftAction()
  deleteEither.mapRight(() => {
    state.isDeleteLoading = false
    notify({
      title: 'Виджет успешно удален',
      type: 'success',
      duration: 2500
    })
  })
  await api.statisticsInnerApi.createInnerEvent({
    name: 'delete_widget',
    userId: userId.value,
    details: { newWidgetCount: widgetCount.value, oldWidgetCount: widgetCount.value + 1 }
  })
}

// expose
defineExpose({
  open
})
</script>
