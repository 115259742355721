<template>
  <PWPopup
    ref="popupRef"
    title="Добавление виджета"
    :hide-footer="true"
  >
    <div :class="$style.proWidget">
      Добавление нового виджета доступно только на тарифе PRO. <br>
      <NuxtLink
        :class="$style.proWidgetLink"
        :to="{ name: 'tariff' }"
        @click="hide"
      >
        Подключить PRO-тариф
      </NuxtLink>
    </div>
  </PWPopup>
</template>

<script setup lang="ts">
import PWPopup from '~/components/_ui/PWPopUp.vue'

// refs
const popupRef = ref<typeof PWPopup>()

// methods
const open = () => {
  popupRef.value?.toggle(true)
}

const hide = () => {
  popupRef.value?.toggle(false)
}

// expose
defineExpose({
  open
})
</script>

<style lang="scss" module>
.proWidget {
  padding: 12px 16px;
  margin-bottom: 16px;
  background: var(--yellow-shade-12);
  font-size: 14px;
}

.proWidgetLink {
  color: var(--main-blue);

  &:visited {
    color: var(--main-blue);
  }
}
</style>
