<i18n lang="yaml">
ru:
  DemoTariffNotificationPopupTitle: "Дарим 7 дней демо-доступа!"
  DemoTariffNotificationPopupButton: "Круто, погнали!"
en:
  DemoTariffNotificationPopupTitle: "We're giving you 7 days of demo access!"
  DemoTariffNotificationPopupButton: "Cool, Let's go!"
</i18n>

<template>
  <div :class="$style.demoTariffNotificationPopup">
    <div v-click-outside="handleClose" :class="$style.window">
      <button :class="$style.close" @click="handleClose">
        <CloseRounded />
      </button>
      <img src="assets/default-images/DemoTariffNotificationHeaderImage.png" :class="$style.demoTariffNotificationHeaderImage">
      <div :class="$style.textContainer">
        <h3 :class="$style.title">
          {{ t$('DemoTariffNotificationPopupTitle') }}
        </h3>
        <p :class="$style.description">
          Протестируйте весь функционал Yourgood.Widget и&nbsp;оптимизируйте настройки для достижения максимальной конверсии. А начать советуем с установки виджета.
        </p>
        <div :class="$style.button">
          <PFButton @click="handleClose">
            {{ t$('DemoTariffNotificationPopupButton') }}
          </PFButton>
        </div>
        <a :class="$style.branding" href="https://widget.yourgood.ru/" target="_blank">
          <YourGoodsLogo />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PFButton from '~/components/_ui/PFButton.vue'
import YourGoodsLogo from '~/assets/icons/YourGoodsLogo.svg?component'
import CloseRounded from 'assets/icons/CloseRounded.svg?component'

export default {
  components: { YourGoodsLogo, PFButton, CloseRounded },
  setup () {
    const { t: t$ } = useI18n()
    return {
      t$
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style module lang="scss">
  .demoTariffNotificationPopup {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.2);
  }

  .window {
    width: 620px;
    height: 470px;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    box-shadow: 0 10px 20px 1px rgba(33, 33, 33, 0.04), 0 20px 40px 0 rgba(33, 33, 33, 0.04);
    background: var(--white);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .demoTariffNotificationHeaderImage {
    width: 100%;
    height: 176px;
    object-fit: cover;
  }

  .textContainer {
    width: 100%;
    height: calc(100% - 176px);
    padding: 32px 24px 24px;
    position: relative;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    text-align: center;
    margin-bottom: 8px;
    color: var(--primary);
  }

  .description {
    max-width: 470px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary);
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .branding {
    text-decoration: none;
    color: var(--primary);
    width: 137px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
  }

  .close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 32px;
    height: 32px;
    background: var(--white);
    border-radius: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .close * {
    fill: var(--primary);
  }

</style>
