<template>
  <div :class="$style.preloaderContainer">
    <div :class="$style.preloaderBody">
      <div :class="$style.preloader">
        <PFLoader />
      </div>
      <p :class="$style.preloaderText">
        {{ preloaderText }}
      </p>
    </div>
  </div>
</template>

<script>
import PFLoader from '~/components/_ui/PFLoader.vue'
export default {
  name: 'PWFullScreenPreloader',
  components: {
    PFLoader
  },
  props: {
    preloaderText: {
      type: String,
      default: 'Применяем изменения'
    }
  }
}
</script>

<style module lang="scss" scoped>

.preloaderContainer {
  display: flex;
  z-index: 533;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #f8f9fb;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.preloaderBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 157px;
  height: 60px;
  z-index: 534;
  background-color: transparent;
}

.preloaderText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  color: #2962ff;
  font-family: 'Roboto', sans-serif;
}

</style>
