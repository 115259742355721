<template>
  <div>
    <PWNotificationParent />

    <Transition name="fade">
      <DemoTariffNotificationPopup v-if="isDemoTariffNotificationVisible" @close="handleDemoTariffNotificationClose" />
    </Transition>

    <div
      :class="{
        [$style.cabinet]: true,
        [$style.mobileCabinet]: isNavFolded
      }"
    >
      <TheNav />

      <main
        id="main"
        :class="{
          [$style.cabinetBody]: true,
          [$style.cabinetBodyWithoutPadding]: isNavFolded
        }"
      >
        <slot :key="currentSettingsId" />
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '~/store/auth'
import TheNav from '~/components/Layouts/TheNav/TheNav.vue'
import PWNotificationParent from '~/components/_ui/PWNotificationParent.vue'
import { setCookie } from '~/utils/cookies'
import DemoTariffNotificationPopup from '~/components/Billing/DemoTariffNotificationPopup.vue'
import { timeout } from '~/utils/timeout'
import { useSettingsStore } from '~/store/settings'
import { useSubscriptionStore } from '~/store/subscription'
import { useApi } from '~/composables/useApi'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const settingsStore = useSettingsStore()
const subscriptionStore = useSubscriptionStore()
const api = useApi()

const headInput = route.name === 'settings-with-offer-modal'
  ? {}
  : {
      script: [
        {
          hid: 'widget',
          innerHTML: `
          (function () { var widget = document.createElement('script'); widget.dataset.pfId = '5a7fc0f5-4113-4a6c-9637-83f98a11611c'; widget.src = 'https://widget.yourgood.app/script/widget.js?id=5a7fc0f5-4113-4a6c-9637-83f98a11611c&&now='+Date.now(); document.head.appendChild(widget); })()
        `
        }
      ],
      __dangerouslyDisableSanitizers: ['script']
    }

useHead(headInput)

const isDemoTariffNotificationVisible = ref(false)

const user = computed(() => {
  const authStoreUser = authStore.user

  if (!authStoreUser) {
    console.error('No user provided. u: ', authStoreUser)
    return undefined
  }

  return authStore.user
})

const userId = computed(() => {
  return user.value?.id
})

const settings = computed(() => {
  if (!settingsStore.settings) {
    console.log('user', user?.value)
    console.error('No settings found in session store. settings: ', settingsStore.settings)
    return undefined
  }

  if (settingsStore.settings?.length === 0) {
    console.error('Settings count is 0: ', settingsStore.settings?.length)
    return []
  }

  return settingsStore.settings
})

const currentSettingsId = computed(() => {
  return settingsStore.currentSettingsId
})

const subscriptionInfo = computed(() => {
  return subscriptionStore.subscriptionInfo
})

const isNavFolded = computed(() => {
  return route.name === 'actionsEditor' || route.name === 'appearance'
})

watch(currentSettingsId, (currentSettingsId: string | null) => {
  try {
    if (!currentSettingsId) {
      currentSettingsId = (route.query.settings ?? settings.value[0].id) as string
    }

    const currentSettings = settings.value.find((s): any => s.id === currentSettingsId)
    if (currentSettings) {
      // this.$store.commit('settings/SET_CURRENT_SETTINGS_ID', currentSettingsId)
      settingsStore.SET_CURRENT_SETTINGS_ID(currentSettingsId)

      if (route.query.settings !== currentSettingsId) {
        router.replace({
          ...route,
          query: {
            ...route.query,
            settings: currentSettingsId
          }
        })
        settingsStore.checkHasWidgetThreeClicks()
      }
    }
  } catch (e) {
    console.log('user', user?.value)
    console.error('Error during watch "currentSettingsId". Error: ', e)
  }
}, { immediate: true })

watchEffect(() => {
  const currentSettingsId = settingsStore.currentSettingsId

  if (route.query.settings !== currentSettingsId) {
    router.replace({
      ...route,
      query: {
        ...route.query,
        settings: currentSettingsId
      }
    })
  }
})

const tryShowDemoTariffNotification = async () => {
  const isDemoTariffNotificationSeenResult = await api.statisticsInnerApi.isDemoTariffNotificationSeen()

  if (isDemoTariffNotificationSeenResult.value) { return }

  if (isDemoTariff()) {
    await timeout(2000)
    isDemoTariffNotificationVisible.value = true
    await api.statisticsInnerApi.createInnerEvent({ name: 'demo_tariff_notification_seen', userId: userId.value, details: {} })
  }
}

const isDemoTariff = () => {
  return subscriptionInfo.value?.subscriptionPlan?.planName === 'demo'
}

const handleDemoTariffNotificationClose = () => {
  if (isDemoTariffNotificationVisible.value) {
    isDemoTariffNotificationVisible.value = false
    api.statisticsInnerApi.createInnerEvent({ name: 'demo_tariff_notification_close', userId: user.value.id, details: {} })
  }
}

onMounted(() => {
  // @TODO:
  // if (this.$i18n.locale !== 'ru') {
  //   this.$i18n.locale = 'ru'
  // }

  subscriptionStore.startTimer()
  settingsStore.checkHasWidgetThreeClicks()

  api.statisticsInnerApi.createInnerEvent({ name: 'enter_dashboard', userId: userId.value, details: {} })

  tryShowDemoTariffNotification()
  setCookie('__num', userId.value, 365)
})
</script>

<style module lang="scss">
@import "assets/scss/shared";

body {
  background: #f8f9fb;
}

.cabinet {
  display: grid; // todo: change to flex after fix TheNav.vue
  grid-template-columns: 112px 1fr;
  height: 100%;
  position: relative;

  @media (min-width: 960px) {
    grid-template-columns: 312px 1fr;
  }

  &.mobileCabinet {
    grid-template-columns: 112px 1fr;
  }
}

//.content {
//  @media (min-width: 960px) {
//    margin: 32px 32px 0 32px;
//  }
//
//  @media (max-width: 960px) {
//    margin: 16px;
//    padding-bottom: 32px;
//  }
//}

.cabinetBody {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--background);
  padding: 32px;

  &.cabinetBodyWithoutPadding {
    padding: 0;
  }
}
</style>

<style>
.slide-leave-active,
.slide-enter-active {
  transition: transform 0.5s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(100%, 0);
}
</style>
