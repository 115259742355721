<template>
  <div v-if="!state.isAddLoading">
    <PWPopup
      ref="popupRef"
      title="Добавление виджета"
      @after-enter="inputNameRef && inputNameRef?.focus()"
    >
      <form @submit.prevent="handleSubmit">
        <PWInput
          ref="inputNameRef"
          v-model="state.form.name"
          placeholder="Название виджета"
          :is-error="v$.form.name.$error || state.uniqueNameError"
          @input="state.uniqueNameError = false"
        />
        <span
          v-if="v$.$error && !v$.form.name.required"
          :class="$style.error"
        >
          Данное поле не может быть пустым
        </span>
        <span
          v-else-if="state.uniqueNameError"
          :class="$style.error"
        >
          Данное название уже используется
        </span>
      </form>

      <template #footer>
        <PWButton
          variant="outline"
          :disabled="state.isAddLoading"
          @click="cancel"
        >
          Отменить
        </PWButton>
        <PWButton :disabled="state.isAddLoading" @click="handleSubmit">
          Добавить
        </PWButton>
      </template>
    </PWPopup>
  </div>
  <div v-else>
    <PWFullScreenPreloader />
  </div>
</template>

<script setup lang="ts">
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useNotification } from '@kyvg/vue3-notification'
import { useSettingsStore } from '~/store/settings'
import { useAuthStore } from '~/store/auth'
import PWPopup from '~/components/_ui/PWPopUp.vue'
import PWInput from '~/components/_ui/PWInput.vue'
import PWButton from '~/components/_ui/Buttons/PWButton.vue'
import PWFullScreenPreloader from '~/components/_ui/PWFullScreenPreloader.vue'
import { useApi } from '~/composables/useApi'
import type { Settings } from '~/types/settings'
import { removeDraftAction } from '@/utils/draftTools'

const settingsStore = useSettingsStore()
const authStore = useAuthStore()
const api = useApi()
const { notify } = useNotification()
const route = useRoute()
const router = useRouter()

// data
const state = reactive({
  form: {
    name: 'Виджет 1'
  },
  isAddLoading: false,
  uniqueNameError: false
})

// emits
const emit = defineEmits(['cancel'])

// refs
const popupRef = ref<typeof PWPopup>()
const inputNameRef = ref<typeof PWInput>()

// vuelidate
const v$ = useVuelidate({
  form: {
    name: {
      required
    }
  }
}, state)

// computed
const settings = computed(() => settingsStore.settings)
const userId = computed(() => authStore.user?.id)
const widgetCount = computed(() => settingsStore.widgetCount)

// methods
const open = () => {
  popupRef.value?.toggle(true)
  state.form.name = `Виджет ${settings.value?.length + 1}`
}

const cancel = () => {
  popupRef.value?.toggle(false)
  emit('cancel')
}

const handleSubmit = async () => {
  v$.value.$touch()
  if (v$.value.$error) {
    return
  }

  const isNameUnique = checkForUniqueSettingName(state.form.name)
  if (!isNameUnique) {
    state.uniqueNameError = true
    return
  }

  state.isAddLoading = true
  const addEither = await settingsStore.addSettings(state.form.name)

  addEither.mapRight(() => {
    state.isAddLoading = false
    notify({
      title: 'Виджет успешно добавлен',
      type: 'success',
      duration: 2500
    })
  })
  await api.statisticsInnerApi.createInnerEvent({
    name: 'add_widget',
    userId: userId.value,
    details: { newWidgetCount: widgetCount.value, oldWidgeCount: widgetCount.value - 1 }
  })
  if (route.path === '/actions/editor') {
    removeDraftAction()
    await router.push({ path: '/actions', query: { ...route.query } })
  }
}

const checkForUniqueSettingName = (name: string) => {
  return !settings.value?.some((setting: Settings) => setting.name === name)
}

// expose
defineExpose({
  open
})
</script>

<style module lang="scss">
.error {
  display: block;
  font-size: 12px;
  color: var(--main-red);
}
</style>
