<template>
  <div
    :class="[
      $style.menuLi,
      {[$style.isActive]: isActive},
      {[$style.hiddenMenuTitles]: isNavFolded}
    ]"
    @click="(e) => $emit('click', e)"
  >
    <div :class="$style.icon">
      <slot name="icon" />
    </div>
    <div>
      <span :class="$style.title">{{ title }}</span>
      <span
        v-if="subtitle"
        :class="$style.subtitle"
      >
        {{ subtitle }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isNavFolded () {
      return this.$route.name === 'actionsEditor' || this.$route.name === 'appearance'
    }
  }
}
</script>

<style module lang="scss">
@import "assets/scss/OldUI/newUI/shared";

@mixin hide-title-subtitles {
  .title,
  .subtitle {
    display: none;
  }
}

.menuLi {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 28px 12px 8px;
  box-sizing: border-box;
  margin-top: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: $gray-100;

  @media (max-width: 960px) {
    @include hide-title-subtitles;
  }

  &.hiddenMenuTitles {
    @include hide-title-subtitles;
  }

  &.isActive {
    background: $blue-30;
    color: var(--smooth-blue) !important;
  }

  &:hover {
    background: $blue-30;
  }
}

.icon {
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.title {
  display: block;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.subtitle {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey-900);
}

</style>
