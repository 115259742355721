<template>
  <nav
    :class="{
      [$style.menu]: true,
      [$style.mobileMenu]: isNavFolded
    }"
  >
    <PopupAddWidget ref="popupAddWidgetRef" />
    <PopupRenameWidget ref="popupRenameWidgetRef" />
    <PopupTryProTariff ref="popupTryProTariffRef" />
    <PopupDeleteWidget ref="popupDeleteWidgetRef" />

    <div
      :class="{
        [$style.menuContainer]: true,
        [$style.mobileMenuContainer]: isNavFolded
      }"
    >
      <div :class="$style.mainMenu">
        <div v-click-outside="hideWidgets" :class="$style.headerContainer">
          <div :class="$style.logoIcon">
            <YourGoodsLogoWithoutText v-if="!isSubscribed" />
            <YourGoodsBlackLogoWithoutText v-else />
            <div :class="$style.logoTitle">
              <strong>Yourgood</strong>
              <div v-if="settings.length !== 1" :class="$style.currentName">
                {{ currentSettings?.name }}
              </div>
              <div v-else :class="$style.addWidgetBtn" @click="addWidget">
                Добавить виджет
              </div>
            </div>
          </div>
          <div v-if="settings.length !== 1" :class="[$style.addContainer, {[$style.isOpen]: state.isOpenWidget}]" @click="state.isOpenWidget = !state.isOpenWidget">
            <ArrowIcon :class="$style.arrowIcon" :style="{ transform: `rotate(${state.isOpenWidget ? 0 : 180}deg)`}" />
          </div>
          <template v-if="state.isOpenWidget">
            <div :class="$style.widgets">
              <div :class="$style.widgetsContainer">
                <div v-for="(s, i) in settings" :key="s.name" :class="$style.widget" @click="setCurrentSettings(s)">
                  <span :class="{ [$style.disabledText]: i !== 0 && !isSubscribed }">{{ s.name }}</span>
                  <div
                    :class="{ [$style.edit]: true, [$style.disabled]: i !== 0 && !isSubscribed }"
                    @click="editWidget(s, i)"
                  >
                    <LockIcon v-if="i !== 0 && !isSubscribed" />
                    <EditIcon v-else />
                  </div>
                  <div
                    :class="$style.trash"
                    @click="deleteWidget(s)"
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>
              <div
                :class="$style.addWidget"
                @click="addWidget"
              >
                <AddIcon :class="$style.addIcon" />
                Добавить виджет
              </div>
            </div>
          </template>
        </div>

        <NuxtLink
          v-for="link in mainLinks"
          v-slot="{ navigate, isActive }"
          :key="link.title"
          :to="link.to"
          custom
        >
          <TheNavItem
            :title="link.title"
            :is-active="isActive"
            @click="navigate"
          >
            <template #icon>
              <component :is="link.icon" />
            </template>
          </TheNavItem>
        </NuxtLink>
      </div>
      <div :class="$style.footerMenu">
        <TariffSidebarPromo :class="$style.tariffSidebarPromo" />
        <NuxtLink
          v-slot="{ navigate, isActive }"
          :to="{ name: 'tariff' }"
          custom
        >
          <TheNavItem
            title="Тариф"
            :is-active="isActive"
            @click="navigate"
          >
            <template #icon>
              <CreditIcon />
            </template>
          </TheNavItem>
        </NuxtLink>

        <a href="https://widget.yourgood.ru/installing-a-widget-on-a-website" target="_blank">
          <TheNavItem title="Инструкции и помощь">
            <template #icon>
              <HelpIcon />
            </template>
          </TheNavItem>
        </a>

        <NuxtLink
          v-slot="{ navigate, isActive }"
          :to="{ name: 'profile' }"
          custom
        >
          <TheNavItem
            title="Профиль"
            :subtitle="usernameFormatted"
            :is-active="isActive"
            @click="navigate"
          >
            <template #icon>
              <ProfileIcon />
            </template>
          </TheNavItem>
        </NuxtLink>
        <a id="hiddenMyButton" style="display: none;" href="https://widget.yourgood.app/#open" target="_blank">hidden button</a>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { parsePhoneNumber } from 'libphonenumber-js/min'
import { useSettingsStore } from '~/store/settings'
import { useSubscriptionStore } from '~/store/subscription'
import { useAuthStore } from '~/store/auth'
import DashboardIcon from 'assets/icons/menu/dashboard.svg?component'
import DeviceIcon from 'assets/icons/menu/device.svg?component'
import DesignIcon from 'assets/icons/menu/design.svg?component'
import FlashIcon from 'assets/icons/menu/flash.svg?component'
import ShareIcon from 'assets/icons/menu/share.svg?component'
import CreditIcon from 'assets/icons/menu/credit.svg?component'
import HelpIcon from 'assets/icons/menu/help.svg?component'
import ProfileIcon from 'assets/icons/menu/profile.svg?component'
import YourGoodsLogoWithoutText from 'assets/icons/YourGoodsLogoWithoutText.svg?component'
import YourGoodsBlackLogoWithoutText from 'assets/icons/YourGoodsBlackLogoWithoutText.svg?component'
import LockIcon from 'assets/icons/LockIcon.svg?component'
import ArrowIcon from 'assets/icons/Arrow.svg?component'
import EditIcon from 'assets/icons/Edit.svg?component'
import AddIcon from 'assets/icons/Add.svg?component'
import TrashIcon from 'assets/icons/TrashIcon.svg?component'
import PopupDeleteWidget from '~/components/_ui/Popups/PopupDeleteWidget.vue'
import TheNavItem from '~/components/Layouts/TheNav/TheNavItem.vue'
import PopupAddWidget from '~/components/_ui/Popups/PopupAddWidget.vue'
import PopupRenameWidget from '~/components/_ui/Popups/PopupRenameWidget.vue'
import PopupTryProTariff from '~/components/_ui/Popups/PopupTryProTariff.vue'
import TariffSidebarPromo from '~/components/Billing/TariffSidebarPromo.vue'
import type { Settings } from '~/types/settings'

const settingsStore = useSettingsStore()
const subscriptionStore = useSubscriptionStore()
const authStore = useAuthStore()

const route = useRoute()

// data
const state = reactive({
  isOpenWidget: false
})

// refs
const popupAddWidgetRef = ref<typeof PopupAddWidget>()
const popupRenameWidgetRef = ref<typeof PopupRenameWidget>()
const popupTryProTariffRef = ref<typeof PopupTryProTariff>()
const popupDeleteWidgetRef = ref<typeof PopupDeleteWidget>()

// computed
const settings = computed(() => settingsStore.settings)
const currentSettings = computed(() => settingsStore.currentSettings)
const currentSettingsId = computed(() => settingsStore.currentSettingsId)
const user = computed(() => authStore.user)
const isSubscribed = computed(() => subscriptionStore.isSubscribed)
const isSettingsReady = computed(() => settingsStore.isSettingsReady)
const usernameFormatted = computed(() => {
  try {
    if (!user.value) { return '' }
    return parsePhoneNumber(user.value.username)?.formatInternational() || user.value.username
  } catch (e) {
    return user.value.username
  }
})

const mainLinks = computed(() => {
  return [
    {
      to: { name: 'visitors' },
      title: 'Статистика',
      icon: DashboardIcon,
      disabled: !isSettingsReady.value
    },
    {
      to: { name: 'settings' },
      title: 'Установка виджета',
      icon: DeviceIcon
    },
    {
      to: { name: 'appearance' },
      title: 'Дизайн',
      icon: DesignIcon
    },
    {
      to: { name: 'actions' },
      title: 'Призыв к действию',
      icon: FlashIcon
    },
    {
      to: { name: 'integrations' },
      title: 'Интеграции',
      icon: ShareIcon
    }
  ]
})

const isNavFolded = computed(() => {
  return route.name === 'actionsEditor' || route.name === 'appearance'
})

// methods
const addWidget = () => {
  if (isSubscribed.value) {
    popupAddWidgetRef.value?.open()
  } else {
    popupTryProTariffRef.value?.open()
  }

  state.isOpenWidget = false
}

const editWidget = (s: any, i: any) => { // TODO: Types
  if (i !== 0 && !isSubscribed.value) {
    return
  }

  if (isSubscribed.value) {
    popupRenameWidgetRef.value?.open(s)
  } else {
    popupTryProTariffRef.value?.open()
  }

  state.isOpenWidget = false
}

const deleteWidget = (s: any) => {
  popupDeleteWidgetRef.value?.open(s)
  state.isOpenWidget = false
}

const hideWidgets = () => {
  state.isOpenWidget = false
}

const setCurrentSettings = (settings: Settings) => {
  if (settings.id === currentSettingsId.value) {
    return
  }

  state.isOpenWidget = false
  settingsStore.SET_CURRENT_SETTINGS_ID(settings.id)
}

</script>

<style module lang="scss">
@import "assets/scss/OldUI/newUI/shared";

@mixin mobile-menu {
  width: 112px;
}

@mixin mobile-menu-container {
  width: 112px;
  overflow: hidden;
  transition: 0.35s all;

  .tariffSidebarPromo {
    opacity: 0;
    transition: 0.2s;
  }

  &:hover {
    width: 312px !important;

    .logoTitle,
    .addContainer {
      display: flex !important;
      text-wrap: nowrap;
    }

    span {
      display: flex !important;
      text-wrap: nowrap;
    }

    .tariffSidebarPromo {
      opacity: 1 !important;
    }
  }

  .logoTitle,
  .addContainer {
    display: none;
  }
}

.addWidgetBtn {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: var(--smooth-blue);
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 312px;

  @media (max-width: 960px) {
    @include mobile-menu;
  }

  &.mobileMenu {
    @include mobile-menu;
  }

  a {
    text-decoration: none !important;
  }

  .menuContainer {
    overflow-y: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    padding: 16px 16px 24px;
    box-sizing: border-box;
    width: 312px;
    background: $gray-0;
    display: flex;
    flex: 1;
    z-index: 10;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 2px 16px 1px rgba(89, 104, 143, 0.06);

    @media (max-width: 960px) {
      @include mobile-menu-container;
    }

    &.mobileMenuContainer {
      @include mobile-menu-container;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #bdbdbd;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }
  }

  .mainMenu,
  .footerMenu {
    display: flex;
    flex-direction: column;
  }
}

.headerContainer {
  cursor: pointer;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  justify-content: space-between;
}

.logoIcon {
  display: flex;
  color: var(--smooth-blue);
}

.isOpen {
  background: $blue-40 !important;
}

.widgets {
  background: $gray-0;
  box-shadow: 0 4px 8px rgba(89, 104, 143, 0.16);
  border-radius: 4px;
  width: 236px;
  position: absolute;
  padding: 4px 0;
  margin-top: 46px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: $gray-100;
  z-index: 10;
}

.widgetsContainer {
  max-height: 270px;
  overflow-y: scroll;
}

.logoTitle {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  color: $gray-100;
  justify-content: center;
  margin-bottom: 8px;

  strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    height: 20px;
  }
}

.addContainer {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .addCircle {
    width: 20px;
    height: 20px;
    color: $blue-50;
  }

  .arrowIcon {
    color: var(--grey-500);
    width: 24px;
    height: 24px;
  }

  &:hover {
    .addCircle {
      color: var(--main-blue);
    }

    .arrowIcon {
      color: var(--main-blue);
    }

    background: $blue-40;
  }
}

.widget {
  height: 48px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    height: 16px;
    display: inline-block;
    width: 164px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background: $blue-40;
  }
}

.currentName {
  height: 16px;
  display: inline-block !important;
  width: 136px;
  font-size: 12px;
  line-height: 166%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit,
.trash {
  color: $blue-50;

  &:hover {
    color: var(--main-blue);
  }
}

.trash {
  margin-left: 15px;
}

.disabled {
  color: var(--grey-300);
}

.disabledText {
  color: var(--disabled);
}

.addWidget {
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  flex: 1;
  color: var(--main-blue);
  background: #fff;

  &:hover {
    background: $blue-40;
  }
}

.addIcon {
  margin-right: 12px;
}
</style>
